import { KbaRequest } from './../../../../data/admin.data';
'use strict';

import { EProtocolSource, KbaResponse, ProtocolEntry, ProtocolHistory } from "../../../../data/admin.data";
import RestService from "../../../../services/rest.service";
import angular = require("angular");

require('./admin.protocol.component.scss');

export default class AdminProtocolComponent {
  public restrict: any;
  public template: any;
  public controller: any;
  public controllerAs: any;

  constructor() {
    this.restrict = 'E'
    this.template = require('./admin.protocol.component.html');
    this.controller = AdminProtocolComponentController;
    this.controllerAs = 'ctrl';
  }
}

/* @ngInject */
class AdminProtocolComponentController {
  public $uibModal: any;
  public $rootScope: angular.IRootScopeService;
  public $log: angular.ILogService;
  public restService: any;
  public Notification: any;
  public $translate: any;
  public data: ProtocolHistory;
  public kbaHistory: KbaResponse;
  public limit: number;
  public listeners = [];
  public isLoading: boolean = true;
  public isSearching: boolean = false;
  public isDeleting = false;
  public searchFilter: string = '';
  public sources;
  public filteredSources: EProtocolSource[];
  public selection = EProtocolSelection.PROTOCOL;

  constructor($rootScope: angular.IRootScopeService, $uibModal, $log: angular.ILogService, restService: RestService, Notification, $translate, public $filter, public $scope: any) {

    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.restService = restService;
    this.Notification = Notification;
    this.$translate = $translate;

    this.filteredSources = Object.values(EProtocolSource);

    this.data = {
      currentPage: 1
    } as ProtocolHistory
    
    this.kbaHistory = {
      page: 0,
      data: []
    } as KbaResponse;
    this.sources = Object.values(EProtocolSource);

    this.$scope.translatedSource = function (p) {
      return $filter('translate')('ADMIN.PROTOCOL_SOURCES.PROTOCOL_SOURCE_' + p);
    }

    this.limit = 100;
    this.loadProtocol();
  }

  openAdditional(entry: ProtocolEntry) {
    if (!entry.hasAdditionalInformation) {
      return
    }
    this.$uibModal.open({
      template: require('../../../modals/admin/protocol.additional.modal/protocol.additional.modal.html'),
      controller: 'ModalProtocolAdditionalController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        entryId: () => {
          return entry.id;
        }
      }
    });
  }

  doProtocolFiltering(source: EProtocolSource) {
    var lst = this.filteredSources;
    if (lst.indexOf(source) !== -1) {
      lst.splice(lst.indexOf(source), 1);
    } else {
      lst.push(source)
    }
    this.onFilterChanged();
  }

  onFilterChanged() {
    this.data = {
      currentPage: 1
    } as ProtocolHistory
    this.loadProtocol();
  }

  isFilteredProtocol(source: EProtocolSource) {
    var lst = this.filteredSources;
    if (angular.isUndefined(lst)) {
      return true;
    }
    return lst.indexOf(source) !== -1;
  }

  loadProtocol() {
    this.isLoading = true;
    var filter = '';
    if (this.filteredSources.length !== Object.values(EProtocolSource).length) {
      filter = this.filteredSources.toString();
    }
    //Current page starts with 0
    this.restService.getProtocol(this.data.currentPage - 1, this.limit, this.searchFilter, filter, (response) => {
      this.isLoading = false;
      this.data = response.data;
      this.data.currentPage = this.data.currentPage + 1;
    }, (error) => {
      this.isLoading = false;
      this.$log.error(error);
    });
  };

  loadKbaHistory(pageIndex: number) {
    this.isLoading = true;
    this.restService.getKbaHistory(pageIndex, this.limit).then((response) => {
      this.kbaHistory = response;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  kbaNextPage() {
    this.loadKbaHistory(this.kbaHistory.page + 1);
  }

  kbaPreviousPage() {
    this.loadKbaHistory(this.kbaHistory.page - 1);
  }

  /**
   * Open the KBA request modal
   */
  openKbaRequestModal(request: KbaRequest) {
    this.$uibModal.open({
      template: require('../../../modals/admin/kba.request.modal/kba.request.modal.html'),
      controller: 'ModalKbaRequestController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        request: () => {
          return request;
        }
      }
    });
  }

  /**
   * Reset the search filter
   * */
  resetSearchAndReload() {
    this.searchFilter = '';
    this.data.currentPage = 1;
    this.loadProtocol();
  };

  select(currentSelection: EProtocolSelection) {
    this.selection = currentSelection;
    if (this.selection === EProtocolSelection.PROTOCOL) {
      this.loadProtocol();
    } else if (this.selection === EProtocolSelection.KBA) {
      this.loadKbaHistory(this.kbaHistory.page);
    }
  }

}

enum EProtocolSelection {
  PROTOCOL = 'PROTOCOL',
  KBA = 'KBA'
}

