'use strict';

import * as L from 'leaflet';
import { CustomMapLayer, ECustomMapLayerType } from '../data/customMapLayer.data';
import RestService from './rest.service';

/* @ngInject */
export default class MapService {

  constructor(
    private restService: RestService,
    private dataService
    ) {
    }


  public saveLayer(layer: string): void {
    localStorage.setItem("mapLayer", layer)
  }

  /**
   * returns the selected layer from localStorage
   * it is possible that the returned name is undefined or the layer behind this key from getBaseLayers() is undefined
   */
  public getSelectedLayer(): string {
    return localStorage.getItem("mapLayer")
  }

  getBaseLayers() {
    let layers = {};
    this.addOSMLayer(layers);
    this.addTPOLayer(layers);
    this.addHereLayerIfLicenced(layers);
    this.addCustomLayerIfFound(layers);
    return layers;
  }

  /**
   * get the layers on which we can do vehicle tracking without legal problems
   */
  getBaseLayersForTracking() {
    let layers = {};
    this.addOSMLayer(layers);
    this.addTPOLayer(layers);
    this.addCustomLayerIfFound(layers);
    return layers;
  }

  /**
   *  adds OpenStreetMap layer to map
   */
  private addOSMLayer(layers: {}): void {
    let osm = L.tileLayer('https://osm.alamos-gmbh.bayern/tile/{z}/{x}/{y}.png', {
      attribution: '© <a href="https://www.openstreetmap.org/" style="color:black !important">OpenStreetMap contributors</a>, <a  style="color:black !important" href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, © <a href="https://www.alamos-gmbh.com/" style="color:black !important">Alamos GmbH</a>',
      maxZoom: 19
    });
    layers["OpenStreetMap"] = osm;
  }

  /**
   *  adds TopPlusOpen ayer to map
   */
  private addTPOLayer(layers: {}): void {
    let tpo = L.tileLayer.wms('https://sgx.geodatenzentrum.de/wms_topplus_open', {
      format: 'image/png',
      layers: 'web',
      transparent: false,
      maxZoom: 20,
      attribution: '&copy <a href="https://www.onmaps.de" style="color:black !important">onmaps-Karte: ATKIS onmap</a>'
    });
    layers["TopPlusOpen"] = tpo;

    let basemap = L.tileLayer.wms('https://sgx.geodatenzentrum.de/wms_basemapde', {
      format: 'image/png',
      layers: 'de_basemapde_web_raster_farbe',
      transparent: false,
      maxZoom: 20,
      attribution: `© basemap.de / BKG ${new Date().getMonth() + 1}.${new Date().getFullYear()}`
    });
    layers["basemap.de"] = basemap;
  }

  /**
   *  adds HERE-Maps layers to map
   */
  private addHereLayerIfLicenced(layers: {}): void {
    if (!this.hasHereLicence()) {
      return;
    }
    let host = this.restService.getBaseUrl();

    // add street layer
    let urlStreet = host + '/here/street/{z}/{x}/{y}?Authorization=' + this.restService.getAuthHeader();
    let hereStreet = L.tileLayer(urlStreet, {
      maxZoom: 20,
      id: "HERE Straßenkarte",
      attribution: 'Map data: &copy; <a href="https://developer.here.com" style="color:black !important">HERE</a>'
    });
    layers["HERE Straßenkarte"] = hereStreet;

    // add satellite layer
    let urlSatellite = host + '/here/satellite/{z}/{x}/{y}?Authorization=' + this.restService.getAuthHeader();
    let hereSatellite = L.tileLayer(urlSatellite, {
      maxZoom: 20,
      id: "HERE Satellit",
      attribution: 'Map data: &copy; <a href="https://developer.here.com" style="color:black !important">HERE</a>'
    });
    layers["HERE Satellit"] = hereSatellite;
  }

  private hasHereLicence(): boolean {
    return this.dataService.getAccount().licenceDetails.nbrOfHereMaps > 0;
  }

  private addCustomLayerIfFound(layers: {}): void {
    let customLayer: CustomMapLayer = this.dataService.getAccount().customMapLayer;
    if (!customLayer) {
      return;
    }

    if (customLayer.url) {
      let attribution1 = this.extractAttribution(customLayer.url);
      switch(customLayer.type) {
        case ECustomMapLayerType.TILE:
          let tileLayer = L.tileLayer(customLayer.url,{
            attribution: attribution1
          });
          layers[customLayer.name] = tileLayer;
          break;
        case ECustomMapLayerType.WMS:
          let wmsLayer = L.tileLayer.wms(customLayer.url, {
            layers: customLayer.wmsParameters.layers,
            styles: customLayer.wmsParameters.styles,
            version: customLayer.wmsParameters.version,
            format: customLayer.wmsParameters.format,
            attribution: attribution1
          });
          layers[customLayer.name] = wmsLayer;

          break;
      }
    }
    if (customLayer.url2) {
      let attribution2 = this.extractAttribution(customLayer.url2);
      switch (customLayer.type2) {
        case ECustomMapLayerType.TILE:
          let tileLayer = L.tileLayer(customLayer.url2, {
            attribution: attribution2
          });
          layers[customLayer.name2] = tileLayer;
          break;
        case ECustomMapLayerType.WMS:
          let wmsLayer = L.tileLayer.wms(customLayer.url2, {
            layers: customLayer.wmsParameters2.layers,
            styles: customLayer.wmsParameters2.styles,
            version: customLayer.wmsParameters2.version,
            format: customLayer.wmsParameters2.format,
            attribution: attribution2
          });
          layers[customLayer.name2] = wmsLayer;

          break;
      }
    }
    if (customLayer.url3) {
      let attribution3 = this.extractAttribution(customLayer.url3);
      switch (customLayer.type3) {
        case ECustomMapLayerType.TILE:
          let tileLayer = L.tileLayer(customLayer.url3, {
            attribution: attribution3
          });
          layers[customLayer.name3] = tileLayer;
          break;
        case ECustomMapLayerType.WMS:
          let wmsLayer = L.tileLayer.wms(customLayer.url3, {
            layers: customLayer.wmsParameters3.layers,
            styles: customLayer.wmsParameters3.styles,
            version: customLayer.wmsParameters3.version,
            format: customLayer.wmsParameters3.format,
            attribution: attribution3
          });
          layers[customLayer.name3] = wmsLayer;

          break;
      }
    }
    if (customLayer.url4) {
      let attribution4 = this.extractAttribution(customLayer.url4);
      switch (customLayer.type4) {
        case ECustomMapLayerType.TILE:
          let tileLayer = L.tileLayer(customLayer.url4, {
            attribution: attribution4
          });
          layers[customLayer.name4] = tileLayer;
          break;
        case ECustomMapLayerType.WMS:
          let wmsLayer = L.tileLayer.wms(customLayer.url4, {
            layers: customLayer.wmsParameters4.layers,
            styles: customLayer.wmsParameters4.styles,
            version: customLayer.wmsParameters4.version,
            format: customLayer.wmsParameters4.format,
            attribution: attribution4
          });
          layers[customLayer.name4] = wmsLayer;

          break;
      }
    }
  }
  extractAttribution(url: string): string {
    var regex = /https+:\/\/([^\/,\s]+\.[^\/,\s]+?)(?=\/|,|\s|$|\?|#)/g;
    let match = regex.exec(url);
    if(!match) return "";
    let strippedUrl = match[0];
    return '© Karte <a href="' + strippedUrl + '" style="color:blue !important">hier</a> bereitgestellt'
  }

}